import React from 'react'
import { Link } from "react-router-dom"

const Pagination = ( {current, total, url} ) => {
    if(total === 1) return ("")

    var part1 = "";
    var part2 = "";
    var part3 = "";
    var part4 = "";
    var part5 = "";

    if(parseInt(current) === 2) {
        part2 = (<Link to={ url + "?page=" + (current-1)}> { current-1 }</Link>)
    }
    else if(current > 2) {
        part1 = (<Link to={ url + "?page=1"}>«</Link>)
        part2 = (<Link to={ url + "?page=" + (current-1)}> { current-1 }</Link>)
    }
    part3 = "" + current;
    if(current !== total && current < total) {
        part4 = (<Link to={ url + "?page=" + (parseInt(current)+1)}> { parseInt(current)+1 }</Link>)
        if(parseInt(current) < (parseInt(total) - 1)) {
            part5 = (<Link to={ url + "?page=" + total }>»</Link>)
        }
    }

  return (
    <p>
        { part1 } { part1 !== "" ? " | " : ""} { part2 } { part2 !== "" ? " | " : ""} { part3 } { part4 !== "" ? " | " : ""} { part4 } { part5 !== "" ? " | " : ""} { part5 }
    </p>
  )
}

export default Pagination