import React from 'react'
import MusicRecordingList from '../components/MusicRecordingList'
import MusicScoreList from '../components/MusicScoreList'


const MusicPage = () => {

  return (
    <main className="posts">
        <h1>Muzyka</h1>
        <MusicScoreList />
        <MusicRecordingList />
    </main>
  )
}

export default MusicPage