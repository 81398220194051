import React from 'react'

const ErrorPage = () => {
  return (
    <main className='post'>
      <h2 className='post-title'>404</h2>
      <h2>Not found</h2>
    </main>
  )
}

export default ErrorPage