import React from 'react'
import { Link } from "react-router-dom"

const NoteListItem = ({note}) => {
    var url = "";
    if(note.type === "url") {
        return (
            <li>
                <a href={note.url}>
                    <span className='post-title'>
                        {note.title}
                    </span>
                </a>
                <hr />
            </li>
        )
    } else if(note.type === "file") {
        return (
            <li>
                <a href={note.file.file}>
                    <span className='post-title'>
                        {note.title}
                    </span>
                </a>
                <hr />
            </li>
        )
    } else {
        url = "/note/" + note.slug
        return (
            <li>
                <Link to={url}>
                    <span className='post-title'>
                        {note.title}
                    </span>
                </Link>
                <hr />
            </li>
        )
    }
}

export default NoteListItem