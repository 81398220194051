import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Moment from 'moment'

const FaithPostPage = ({ match }) => {

  const navigate = useNavigate()

  let params = useParams()

  let [post, setPost] = useState([])

  useEffect(() => {
      getPost()
  }, [])

  let getPost = async () => {
    let response = await fetch('/api/faith/' + params.slug)
    if(response.status >= 400) {
      navigate('/404')
    }
    let data = await response.json()
    setPost(data)
  }

  return (
    <main className="post">
        <h3 className='post-title'>{ post?.title }</h3>
        <article>
          <small>Dodano: { Moment.utc(post.created_on).format('YYYY-MM-DD HH:mm:ss')}</small>
          <div className='post-content'>
            <center><img src={post.title_image_file?.file ?? null ? post.title_image_file.file : post.title_image_url} alt={post.title}/></center><br />
            <p className='justified' dangerouslySetInnerHTML={{__html: post.body}}/>
          </div>
        </article>
    </main>
  )
}

export default FaithPostPage