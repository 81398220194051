import React from 'react'

const AboutPublicationListItem = ({publication}) => {
  return (
    <li>
        {publication.authors} - <b>{publication.title}</b>. {publication.publisher}, {publication.year}. <a href={publication.url}>[LINK]</a>
    </li>
  )
}

export default AboutPublicationListItem