import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import Moment from 'moment'

const ITPostPage = ({ match }) => {

  let params = useParams()

  const navigate = useNavigate()

  let [post, setPost] = useState([])
  let [tagsLength, setTagsLength] = useState([])
  let [filesCount, setFilesCount] = useState([])

  useEffect(() => {
    getPost()
  }, [])

  let getPost = async () => {
    let response = await fetch('/api/it/' + params.slug)
    if(response.status >= 400) {
      navigate('/404')
    }
    let data = await response.json()
    setPost(data)
    setTagsLength(data.tags.length)
    setFilesCount(data.files.length)
  }

  var tagsList = "";
  if(tagsLength > 0) {
    tagsList = (<p>Tagi: { post.tags.map((t, index) => (
      (<span key={index}><Link to={"/it/tag/" + t.slug}>{t.name}</Link> </span>)
    ))}</p>);
  }

  var filesList = "";
  if(filesCount > 0) {
    filesList = (<p><h4>Pliki do pobrania:</h4> <ul>
      { post.files.map((f, index) => (
        (<li key={index}><a href={f.file}>{f.name}</a></li>)
      ))}
    </ul></p>);
  }

  return (
    <main className="post">
        <h3 className='post-title'>{ post?.title }</h3>
        <article>
          <small>Dodano: { Moment.utc(post.created_on).format('YYYY-MM-DD HH:mm:ss')}</small>
          <div className='post-content'>
            <p className='justified' dangerouslySetInnerHTML={{__html: post.body}}/>
          </div>
          { filesList }
          { tagsList }
          <small>Ostatnia aktualizacja: { Moment.utc(post.updated_on).format('YYYY-MM-DD HH:mm:ss')}</small>
        </article>
    </main>
  )
}

export default ITPostPage