import {
  HashRouter as Router,
  Route,
  Routes
} from "react-router-dom"

import './fonts/Inter-UI-Bold.woff2';
import './fonts/Inter-UI-Italic.woff2';
import './fonts/Inter-UI-Regular.woff2';
import './App.css';
import Header from './components/Header';
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import AboutPage from "./pages/AboutPage";
import MusicPage from "./pages/MusicPage";
import ITPage from "./pages/ITPage";
import ITTagsPage from "./pages/ITTagsPage";
import ITPostPage from "./pages/ITPostPage";
import FaithPage from "./pages/FaithPage";
import FaithPostPage from "./pages/FaithPostPage";
import NotesPage from "./pages/NotesPage";
import NotePage from "./pages/NotePage";
import NoteTagPage from "./pages/NoteTagPage";
import ErrorPage from "./pages/ErrorPage";

function App() {
  return (
    <div className="container">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/about" element={<AboutPage />}></Route>
          <Route path="/it" element={<ITPage />}></Route>
          <Route path="/it/tag/:slug" element={<ITTagsPage />}></Route>
          <Route path="/it/:slug" element={<ITPostPage />}></Route>
          <Route path="/music" element={<MusicPage />}></Route>
          <Route path="/faith" element={<FaithPage />}></Route>
          <Route path="/faith/:slug" element={<FaithPostPage />}></Route>
          <Route path="/notes" element={<NotesPage />}></Route>
          <Route path="/note/:slug" element={<NotePage />}></Route>
          <Route path="/notes/tag/:slug" element={<NoteTagPage />}></Route>
          <Route path="*" element={<ErrorPage />}></Route>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
