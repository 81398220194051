import React, {useEffect,  useState} from 'react'
import { useParams, useSearchParams, useNavigate, Link } from 'react-router-dom'
import Pagination from '../components/Pagination'

const ITTagsPage = () => {

  let params = useParams()

  let [qparams, ] = useSearchParams()
  const page = qparams.get("page") ?? 1
  const navigate = useNavigate()

  let [posts, setPosts] = useState([])
  let [pages, setPages] = useState([])

  useEffect(() => {
    getPosts()
  }, [])

  let getPosts = async () => {
    let response = await fetch('/api/it/tag/' + params.slug + "?page=" + page)
    if(response.status >= 400) {
      navigate('/404')
    }
    let data = await response.json()
    setPosts(data.objects)
    setPages(data.pages)
  }

  return (
    <div>
      <main className='post'>
        <h2 className='post-title'>IT ({ params.slug })</h2>
        { posts.map((post, index) => (
          <article key={index}>
            <h2>{post.title}</h2>
            <div className='post-content'>
              <p className='justified'>{ post.summary }</p>
            </div>
            <p className='pull-right'><Link to={"/it/" + post.slug}>Czytaj dalej</Link></p>
            <hr />
          </article>
        ))}
      </main>
      <Pagination total={pages} current={page} url={"/it/tag/" + params.slug} />
    </div>
  )
}

export default ITTagsPage