import React from 'react'

const MusicRecordingListItem = ({recording}) => {
  return (
    <li>
        <a href={recording.url}>
            <span className='post-title'>
                {recording.title}
                <br />
                <small>
                    {recording.performed_by}.&nbsp;
                    {recording.place_date}.<br />
                    {recording.description}
                </small>
            </span>
        </a>
        <hr />
    </li>
  )
}

export default MusicRecordingListItem