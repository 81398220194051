import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import Moment from 'moment'

const NotePage = ({ match }) => {
  let params = useParams()

  const navigate = useNavigate()

  let [note, setNote] = useState([])
  let [tagsLength, setTagsLength] = useState([])

  useEffect(() => {
    getNote()
  }, [])

  let getNote = async () => {
    let response = await fetch('/api/note/' + params.slug)
    if(response.status >= 400) {
      navigate('/404')
    }
    let data = await response.json()
    setNote(data)
    setTagsLength(data.tags.length)
  }

  var tagsList = "";
  if(tagsLength > 0) {
    tagsList = (<p>Tagi: { note.tags.map((t, index) => (
      (<span key={index}><Link to={"/notes/tag/" + t.slug}>{t.name}</Link> </span>)
    ))}</p>);
  }

  return (
    <main className="post">
        <h3 className='post-title'>{ note.title }</h3>
        <article>
          <small>Dodano: { Moment.utc(note.created_on).format('YYYY-MM-DD HH:mm:ss')}</small>
          <div className='post-content'>
            <p className='justified' dangerouslySetInnerHTML={{__html: note.body}}/>
          </div>
          { tagsList }
          <small>Ostatnia aktualizacja: { Moment.utc(note.updated_on).format('YYYY-MM-DD HH:mm:ss')}</small>
        </article>
    </main>
  )
}

export default NotePage