import React, { useState, useEffect } from 'react'
import MusicRecordingListItem from '../components/MusicRecordingListItem'

const MusicRecordingList = () => {

    let [recordings, setRecordings] = useState([])

    useEffect(() => {
        getRecordings()
    }, [])

    let getRecordings = async () => {
        let response = await fetch('/api/music-recordings/')
        let data = await response.json()
        setRecordings(data)
    }

  return (
    <div className='posts-group'>
        <div className='post-year'>Nagrania</div>
        <ul className='posts-list'>
            {recordings.map((recording, index) => (
                <MusicRecordingListItem key={index} recording={recording} />
            ))}
        </ul>
    </div>
  )
}

export default MusicRecordingList