import React from 'react'
import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer__inner">
            <div className="footer__content">
                <span>© 2019-{(new Date().getFullYear())}</span>
                <span><Link to="/">Dawid Galus</Link></span>
            </div>
        </div>
        <div className="footer__inner">
            <div className="footer__content">
                <small>Theme by <a href="https://github.com/rhazdon">rhazdon</a></small>
            </div>
        </div>
    </footer>
  )
}

export default Footer