import React from 'react'

const MusicScoreListItem = ({score}) => {
  return (
    <li>
        <span class="post-title">
        { score.title } &nbsp; &nbsp;
        { score.pdf_file != null ?
            <a href={score.pdf_file.file}>[PDF]</a> : ''
        } &nbsp;
        { score.source_file != null ?
            <a href={score.source_file.file}>[RAW]</a> : ''
        }
        </span>
        <hr />
    </li>
  )
}

export default MusicScoreListItem