import React, { useState, useEffect } from 'react'
import MusicScoreListItem from './MusicScoreListItem'

const MusicScoreList = () => {

    let [categories, setCategories] = useState([])

    useEffect(() => {
        getCategories()
    }, [])

    let getCategories = async () => {
        let response = await fetch('/api/music-score-categories-with-scores/')
        let data = await response.json()
        setCategories(data)
    }

  return (
    <div className='posts-group'>
        <div className='post-year'>Partytury</div>
        <ul className='posts-list'>
            {categories.map((category, index) => (
                <div className='list-block'>
                    <div className='post-year sm'>
                        {category.name}
                    </div>
                    <ul className='posts-list'>
                    {category.music_scores.sort((a, b) => (a.title > b.title) ? 1 : -1).map((score, idx) => (
                        <MusicScoreListItem index={idx} score={score}/>
                    ))}
                    </ul>
                </div>
            ))}
        </ul>
    </div>
  )
}

export default MusicScoreList