import React, {useEffect,  useState} from 'react'
import AboutPublicationListItem from '../components/AboutPublicationListItem'

const AboutPage = () => {

    let [publications, setPublications] = useState([])

    useEffect(() => {
        getPublications()
    }, [])

    let getPublications = async () => {
        let response = await fetch('/api/about-publications/')
        let data = await response.json()
        setPublications(data)
    }

  return (
    <main className="post">
        <article>
            <h2 className="post-title">O mnie</h2>
            <div className="post-content">
                <h4>Kim jestem?</h4>
                <p className='justified'>
                Witaj,<br />
                nazywam się Dawid Galus, a to moja stronka, na której umieszczam różne materiały mojej produkcji. ;)
                Zawodowo zajmuję się informatyką (głównie administracją sieciami komputerowymi i programowaniem).
                Czasami poszukuję też błędów bezpieczeństwa w różnego rodzaju oprogramowaniu. Gram w CTFy z zespołem 1753c.<br />
                Moim drugim światem jest muzyka, szczególnie ta kościelna.
                Oprócz bycia organistą zajmuję się również tworzeniem aranżacji i orkiestracją utworów dla zespołów (orkiest i scholi), w których gram lub które prowadzę. <br />
                Trzeci świat, w którym się obracam, to świat wiary katolickiej. Staram się ją zrozumieć, żyć nią na codzień i umieć przekazywać innym.
                Jestem świeckim dominikaninem.
                </p>

                <h4>Edukacja</h4>
                <ul>
                    <li>
                        Dominikańskie Studium Filozofii i Teologii w Krakowie.
                    </li>
                    <li>
                        <b>Magister inżynier</b> - Politechnika Opolska, Wydział Elektrotechniki, Automatyki i Informatyki.<br />
                        <small>Kierunek:</small> informatyka. <br />
                        <small>Tytuł pracy magisterskiej: </small><i>Zastosowanie analizy behawioralnej w zagadnieniach bezpieczeństwa sieci komputerowej.</i><br />
                        <small>Dyplom z wyróżnieniem.</small>
                    </li>
                    <li>
                        <b>Inżynier</b> - Politechnika Opolska, Wydział Elektrotechniki, Automatyki i Informatyki.<br />
                        <small>Kierunek:</small> informatyka. <br />
                        <small>Tytuł pracy inżynierskiej: </small><i>Opracowanie technologii umożliwiającej realizację inteligentnego routera.</i><br />
                        <small>Dyplom z wyróżnieniem.</small>
                    </li>
                    <li>
                        <b>Technik informatyk</b> - Zespół Szkół Elektrycznych im. T. Kościuszki w Opolu.<br />
                    </li>
                    <li>
                        Studium Muzyki Kościelnej w Opolu<br />
                        <small>Kierunek:</small> organista. <br />
                    </li>
                </ul>

                <h4>Publikacje</h4>
                <ul>
                    {publications.map((publication, index) => (
                        <AboutPublicationListItem key={index} publication={publication} />
                    ))}
                </ul>

                <h4>Zainteresowania</h4>
                <ul>
                    <li>Bezpieczeństwo komputerowe</li>
                    <li>Programowanie</li>
                    <li>Muzyka klasyczna i liturgiczna</li>
                    <li>Duchowość</li>
                    <li>Teologia</li>
                    <li>Trekking i wspinaczka</li>
                    <li>Podróże.</li>
                </ul>
            </div>
        </article>
    </main>
  )
}

export default AboutPage