import React, { useState, useEffect } from 'react'
import NoteListItem from '../components/NoteListItem'

const NotesPage = () => {

    let [tags, setTags] = useState([])

    useEffect(() => {
        getTags()
    }, [])

    let getTags = async () => {
        let response = await fetch('/api/note-tags-with-notes/')
        let data = await response.json()
        setTags(data)
    }

  return (
    <main className="posts">
        <h1>Notatki</h1>
        {tags.map((tag, index) => (
            <div key={index} className='posts-group'>
                <div className='post-year'>{tag.name}</div>
                <ul className='posts-list'>
                    {tag.notes.map((note, idx) => (
                        <NoteListItem key={idx} note={note}/>
                    ))}
                </ul>
            </div>
        ))}
    </main>
  )
}

export default NotesPage