import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Pagination from "../components/Pagination";

const FaithPage = () => {
  const location = useLocation();

  let [params] = useSearchParams();
  const page = params.get("page") ?? 1;

  const navigate = useNavigate();

  let [posts, setPosts] = useState([]);
  let [pages, setPages] = useState([]);

  let getPosts = async () => {
    let response = await fetch("/api/faith/?page=" + page);
    if (response.status >= 400) {
      navigate("/404");
    }
    let data = await response.json();
    setPosts(data.objects);
    setPages(data.pages);
  };

  useEffect(() => {
    getPosts();
  }, [location.key]);

  return (
    <div>
      <main className="post">
        <h2 className="post-title">Wiara</h2>
        {posts.map((post, index) => (
          <article key={index}>
            <h2>{post.title}</h2>
            <div className="post-content">
              <p className="justified">{post.summary}</p>
            </div>
            <p className="pull-right">
              <Link to={"/faith/" + post.slug}>Czytaj dalej</Link>
            </p>
            <hr />
          </article>
        ))}
      </main>
      <Pagination total={pages} current={page} url={"/faith/"} />
    </div>
  );
};

export default FaithPage;
