import React from 'react'
import { Link } from "react-router-dom"

const Header = () => {
  return (
    <header className="header">
        <span className="header__inner">
            <a href="/" className="main_logo">
                <div className="logo">
                    <span className="logo__text">$ cd /home/avc</span>
                    <span className="logo__cursor"></span>
                </div>
            </a>
            <span className="header__right">
                <nav className="menu">
                    <ul className="menu__inner">
                        <li><Link to="/about">O mnie</Link></li>
                        <li><Link to="/it">IT</Link></li>
                        <li><Link to="/music">Muzyka</Link></li>
                        <li><Link to="/faith">Wiara</Link></li>
                        <li><Link to="/notes">Notatki</Link></li>
                    </ul>
                </nav>
            </span>
        </span>
    </header>
  )
}

export default Header