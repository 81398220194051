import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import NoteListItem from '../components/NoteListItem'

const NoteTagPage = ({ match }) => {
  let params = useParams()

  const navigate = useNavigate()

  let [notes, setNotes] = useState([])

  useEffect(() => {
    getNotes()
  }, [])

  let getNotes = async () => {
    let response = await fetch('/api/notes/tag/' + params.slug)
    if(response.status >= 400) {
      navigate('/404')
    }
    let data = await response.json()
    setNotes(data)
  }

  return (
    <main className="posts">
        <h1>Notatki ({ params.slug })</h1>
        <div className='posts-group'>
            <ul className='posts-list'>
                {notes.map((note, idx) => (
                    <NoteListItem key={idx} note={note}/>
                ))}
            </ul>
        </div>
    </main>
  )
}

export default NoteTagPage